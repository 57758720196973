@import "~angular2-multiselect-dropdown/themes/default.theme.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@mixin table-pagination {
  display: flex;
  justify-content: space-between;
  color: #4b597a;
  font-size: 13px;
  margin: 0.5rem 0;

  .custom-select {
    border: none;

    &:focus {
      box-shadow: none;
    }
  }
}

//body changes(color,background-color,font)
body {
  font-family: "Poppins";
  background-color: #f6f6f6;
  color: #07162b;
  font-size: 14px;
}

// side menu section design change
.side-nav {
  background-color: #07162b !important;
  box-shadow: none !important;
  overflow: visible !important;
}

.side-nav.wide .collapsible .card .card-header a h5 {
  font-size: 14px !important;
  font-weight: 500 !important;
  padding-left: 28px !important;
}

.side-nav.wide.slim .collapsible .card .card-header a h5 {
  padding-left: 20px !important;
}

// side menu section design change -  overwrite
.white-skin .side-nav .sidenav-bg:after,
.white-skin .side-nav .sidenav-bg.mask-strong:after {
  background: none;
}

.side-nav .collapsible .card .card-header a {
  margin-bottom: 2px !important;
}

// side menu item active background color change
.menu-active .card-header a,
.router-link-active .card-header a {
  border-radius: 0 !important;
  background-color: #00e5ff !important;
}

// side menu item active text color change
.menu-active .card-header a h5,
.router-link-active .card-header a h5,
.blue-text {
  color: #07162b !important;
}

.white-skin .menu-active .card-header a h5 img {
  filter: invert(1);
}

.side-nav .collapsible .card .card-header a .rotate-icon {
  margin-right: 28px !important;
}

// side menu item default text color change
.white-skin .side-nav .collapsible .card .card-header a h5,
.white-skin
  .side-nav
  .collapsible
  .card.active
  > mdb-accordion-item-head
  .card-header
  a
  h5 {
  color: #d1faff;
}

// side menu subitem default text color change
.side-nav .collapsible .card-body li a,
.white-skin .side-nav .collapsible li a:not(.collapsible-header):hover {
  color: #000 !important;
  font-weight: 700;
}

// side menu section - collapse icon design
.sidenav-collapse-icon {
  width: 30px;
  height: 30px !important;
  position: relative;
  display: flex !important;
  background-color: #febe10;
  align-items: center;
  right: 0;
  margin-top: -5px;
  border-radius: 50%;
  justify-content: center;
  color: #07162b !important;
  padding: 0 !important;
}

.double-nav.navbar .dropdown-toggle.btn-primary {
  background: none !important;
  margin: 0 10px 0 0;

  &:active {
    background-color: transparent !important;
  }

  & > div {
    display: flex;
    align-items: center;

    .userIcon {
      width: 40px;
      height: 40px;
    }

    .mdb-accordion-indicator {
      position: relative;
      margin: 0;
      top: 0;
      bottom: 0;
      margin-left: 7px;
      vertical-align: top;
      margin-top: -5px;
    }
  }
}

.navbar .dropdown-toggle:after {
  display: none;
}

.double-nav.navbar {
  .dropdown {
    .dropdown-menu {
      margin-left: 0;
      right: 18px;
      left: auto;
      min-width: 200px;
      
      &.notification {
        height: 500px;
        width: 350px !important;

        .noti-body {
          height: 431px;          
          .noti-message-container {
            font-size: 13px;
            cursor: pointer;

            .noti-date {
              font-size: 12px;
              font-weight: 400;
              color: gray;              
            }
          }

          .empty-content {
            margin: 38% auto;
            .notificaiton-icon-container {
              height: 125px;
              width: 125px;
              position: relative;

              .sub-icon-container {
                position: absolute;
                height: 30px;
                width: 30px;
                background: #fff;
                top: 10px;
                right: 10px;              
              }
            }
          }
        }
      }
    }
  }
}

.side-nav .collapsible a:hover,
.accordion .card .card-header,
.side-nav .collapsible a,
.side-nav .collapsible .card-body li a:hover {
  color: inherit !important;
}

.side-nav .collapsible .card-body li a {
  padding-left: 82px !important;
  font-size: 14px !important;
}

.mdb-accordion-indicator:after {
  border-width: 6px 4px 0 4px !important;
  padding: 0 !important;
  border-left-color: transparent;
  border-right-color: transparent;
  transform: rotate(0deg) !important;
}

// primary button design changes
.white-skin .btn {
  padding: 0 12px !important;
  text-transform: capitalize !important;
  font-size: 0.875rem !important;
  box-shadow: none !important;
  border-radius: 4px;
  // &:focus
  // {
  //   background-color: inherit !important;
  // }
  & > mdb-icon
  {
    padding-right:3px;
  }
}

.white-skin .btn-primary {
  background-color: #febe10 !important;
  color: #07162b !important;
  font-weight: 500;
  letter-spacing: 0.04em;
  border: 2px solid #febe10;
}

.white-skin .btn-outline-primary {
  border: 2px solid #febe10 !important;
  color: #febe10 !important;

  &:hover {
    border: 2px solid #febe10 !important;
    color: #febe10 !important;
  }
}

.primary,
.text-primary {
  color: #000 !important;
}

.mdb-select-arrow.focused {
  color: inherit !important;
}
.white-skin .btn-primary:not([disabled]):not(.disabled):active
{
  background-color: #00000024 !important;
  border-color: #00000054 !important;
}

//card background color changes

.card {
  background-color: #f2f9fa;
  box-shadow: none !important;
}

// table design changes

table:not(.picker__table):not(.ck-content .table table) {
  width: 100%;
  border-radius: 0 !important;
  border: none !important;

  thead {
    position: relative !important;
    bottom: 10px;
    background-color: #fafafa !important;

    tr {
      border-bottom: none !important;

      th {
        font-weight: 500;
        letter-spacing: 0.02em;
        padding: 10px;
        color: #5f6868;
        border: none !important;
      }
    }
  }

  tbody {
    tr {
      background-color: #fff;
      border-bottom: 1px solid #f2f2f2;

      &:hover:not(.grid-stack table tbody tr){
        outline: 1px solid #00e5ff;     
      }
      // &  table tbody tr:hover
      // {
      //   outline:1px solid transparent !important;
      // }
    }

    td {
      font-weight: 400;
      padding: 14px 10px;
      color: #5f6868;
      border: none !important;
      a
      {
        &:hover
        {
          color: #00e5ff !important;
        }
      }
      & .dropdown.show .dropdown-menu
      {
        right: 0;
        top: -16px;
        left: auto;
      }
      &  table thead tr:hover , &  table tbody tr:hover 
      {
        outline:1px solid transparent !important;
      }
    }
  }

  tfoot {
    @include table-pagination;
  }
}

.white-skin main section .card nav {
  @include table-pagination;
}

//pagination

a[aria-label="Previous"] {
  background: none !important;

  span {
    display: none !important;
  }

  &::before {
    border-width: 0 2.2px 2.2px 0;
    padding: 3px;
    content: "";
    display: block;
    border-style: solid;
    transform: rotate(130deg);
  }
}

a[aria-label="Next"] {
  background: none !important;

  span {
    display: none !important;
  }

  &::before {
    border-width: 0 2.2px 2.2px 0;
    padding: 3px;
    content: "";
    display: block;
    border-style: solid;
    transform: rotate(320deg);
  }
}

.pagination .page-item .page-link {
  font-size: 13px;
  color: #4b597a;
  font-weight: 300;
}

.pagination .page-item.active .page-link {
  background-color: transparent;
  box-shadow: none;
  color: #4b597a;
  font-weight: 600;
}

.pagination .page-item.active .page-link:hover {
  background-color: transparent;
}

.page-size-drop,.page-size {
  width: auto;
  font-size: 14px;
  font-weight: 600;
}

//breadcrumb design

.breadcrumb {
  font-size: 13px;
  padding: 1rem 0;
  background-color: transparent;
  margin: auto;
  font-weight: 500;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\f101";
  font-family: "Font Awesome 6 free";
  font-weight: 600;
}

// container design
main {
  margin-top: 62px;
}

.expand-body main {
  padding: 0 35px 0 275px !important;
}

.collapse-body main {
  padding: 0 35px 0 95px !important;
}

section {
  width: 100% !important;
}

.modal-dialog .modal-content {
  border-radius: 10px !important;
  background-color: #f2f2f2 !important;

  & .modal-header {
    text-align: center;
    justify-content: center;
    color: #1c1b1f;
    border-bottom: 1px solid #d9d9d9;
  }

  & .modal-title {
    font-weight: 500;
    width: 100%;
  }

  & .modal-footer {
    border: none;
    justify-content: center;
  }
  &.modal-layout
  {
    background-color: #D7EFF4 !important;
    & .modal-header
    {
      color: #4B597A !important;
      text-align: left;
      justify-content: start;
    }
   
  }

}

.modal-publish-xbrl {
  max-width: 600px;

  .btn {
    padding-left: 1.14rem;
    padding-right: 1.14rem;
    text-transform: none;
  }
}

.header {
  font-size: 25px;
  font-weight: 500;
  padding: 10px 25px;
  color: #07162b;
}

.back-option {
  font-size: 14px;
  color: #07162b;
  font-weight: 500;
  cursor: pointer;
}

.md-form input.form-control,.md-form textarea.form-control,
.mdb-select-value,
.file-field input.file-path {
  box-sizing: border-box;
  width: 100%;
  border: 1px !important;
  border-style: solid !important;
  border-color: #d7eff4 !important;
  border-radius: 6px !important;
  min-height: auto;
  background: #fff !important;
  padding: 0.6rem 0.5rem 0.4rem;
  box-shadow: 0px 0px transparent !important;

  &:focus {
    border: 1px solid #00e5ff !important;
    box-shadow: none !important;
  }
}
.mdb-select-value
{
  padding: 0.6rem 0.5rem 0.4rem !important;
}
.md-form.search {
  // width: 25%;
  margin: 0;
  // padding-right: 40px;

  input {
    padding-right: 62px !important;
    margin-bottom: 0;
  }

  & [icon="search"] {
    position: absolute;
    right: 8px;
    top: 18px;
    color: #d7ecf4;
    padding-right: 7px;
  }

  & [icon="times-circle"] {
    position: absolute;
    right: 40px;
    top: 15px;
    cursor: pointer;
  }
}

.md-form label {
  transform: translateY(-30px) scale(0.8) !important;
}

.filter {
  .column-filter {
    position: relative;

    .filter-button {
      margin: 0;
      // height: 30px;
      // border-radius: 50%;
      // width: 30px;
      background-color: transparent;
      border: 1px solid transparent;

      .filter-icon {
        color: #afb3be;

        &:hover {
          color: #febe10;
        }
      }
    }

    .field-filter-dropdown {
      border: 1px solid #dfdfdf;
      background-color: #f2f2f2;
      border-radius: 8px;
      box-shadow: 0px 2px 50px 0px #0000001a;
      min-width: 180px;
      position: absolute;
      z-index: 999;
      top: 36px;
      left: -155px;

      .filter-title {
        padding: 8px 10px;

        .title-text {
          font-size: 14px;
          font-weight: 500;
          color: #5f6868;
        }
      }

      .fields {
        .filter-option {
          padding: 6px 12px;
          font-size: 14px;
          color: #5f6868;
        }
      }
    }
  }
}

.btn-outline-orange {
  border: 2px solid #ff7919 !important;
  color: #ff7919 !important;
}

body button.action-btn {
  background-color: transparent;
  border: 0;
  border-radius: 4px;

  &:hover {
    color: #fff;
    background-color: black;
  }
}

.dropdown-menu {
  color: #1c1b1f;
  border: 1px solid #d9d9d9;
  background-color: #f2f2f2 !important;
}

body .dropdown .dropdown-menu.dropdown-primary .dropdown-item:hover {
  background-color: #1c1b1f !important;
  color:#00e5ff;
}

.pagination {
  margin: 0;
  align-items: center;
}

.md-form label,
.md-form .mdb-select .mdb-select-label {
  color: #000 !important;
  font-weight: 500 !important;
}

// table.table-sticky thead
// {
//   position: sticky;
//   position: -webkit-sticky;
//   top: 64px;
//   z-index: 1;
// }

// .table thead.sticky
// {
//   background-color:#c5d5e9 !important;
// }

// .table thead.sticky th:first-child,.table thead.sticky th:last-child
// {
//  border-radius: 0 !important;
// }
// .table thead.sticky th
// {
//  border-bottom: none !important;
//  color:#145178 !important;
// }

.badge-version {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #07162b;
  background-color: #f2f9fa !important;
  width: 66px;
  height: 30px;
  box-shadow: none !important;
  font-weight: 400;
}

.mdb-select-dropdown {
  top: 46px;
}

.picker__box .picker__header .picker__nav--prev,
.picker__box .picker__header .picker__nav--next {
  display: none;
}

.datepicker-inline .picker__header {
  padding: 1rem !important;
  margin-bottom: 0 !important;
  justify-content: start;
  flex-flow: row-reverse;
}

.datepicker-icon {
  color: #d7ecf4;
  cursor: pointer;
}

.picker__box .picker__header .picker__select--month,
.picker__box .picker__header .picker__select--year {
  border: none !important;
}


.picker__box .picker__table td.picker__day.disabled {
  color: #e9e9e9;
  background: transparent !important;
  opacity: 0.3;
  cursor: not-allowed !important;
}
.picker__box .picker__table .picker__day--selected {
  background-color: #fb3f4a !important;
  color: #fff !important;
}

.picker__box .picker__table td.picker__day div.picker__day.picker__day--today {
  box-shadow: none;
}

.table-checkbox {
  position: relative;
  top: 5px;
  left: 8px;
}

.custom-choose [icon="file-upload"] {
  position: absolute;
  right: 12px;
  top: 12px;
  color: #d7ecf4;
}

app-search {
  width: 20%;
}

//loader
.loader-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

//tree
.tree-container {
  min-height: 250px;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #fff;
  border: solid 1px #d7eff4;
  border-radius:6px;
  &.disable {
    opacity: 0.5;
  }
}

.cdk-overlay-container {
  z-index: 1090 !important;
}

//copyright
.copyright-text {
  color: #000;
  font-size: 0.6rem;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  bottom: 1%;
  padding: 2px;
  margin: 0;
}

//checkbox
.white-skin input[type="checkbox"]:checked + label:before {
  border-right: 2px solid #febe10;
  border-bottom: 2px solid #febe10;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #febe10;
  background-color: #febe10;
}

.white-skin .bg-primary,
.white-skin .blue {
  background-color: #000 !important;
}

//scale
.w-5{
width: 5%;
}
.w-10{
  width: 10%;
  }
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-35 {
  width: 35%;
}
.w-40 {
  width:40%;
}
.w-45 {
  width:45%;
}
.w-55 {
  width: 55%;
}
.w-60 {
  width: 60%;
}
.w-65 {
  width: 65%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-85 {
  width: 85%;
}
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}

//calendar
.cal-month-view .cal-day-cell.cal-today,
.cal-week-view .cal-header.cal-today {
  background-color: #332d2d7d;
}

.cal-month-view .cal-day-badge {
  background-color: #000 !important;
}

.cal-month-view .cal-cell .cal-event,
.cal-month-view .cal-open-day-events .cal-event {
  background-color: #07162b !important;
}

.cal-month-view .cal-open-day-events {
  background-color: #dfe7f6;
  color: #000;
  box-shadow: none;
  padding: 8px;
}

.cal-week-view .cal-ends-within-day .cal-event,
.cal-month-view .cal-open-day-events {
  text-align: left;
}

//tooltip
.tooltip-inner,
.tooltip,
.tooltip.show {
  z-index: 1100 !important;
  opacity: 1 !important;
   max-width: 280px !important;
}

/* --------- REVISION HISTORY ---------- */

#revision-viewer-container {
  display: none;

  .editor-container {
    position: relative;

    #revision-viewer-editor {
      width: 100%;
    }

    .ck-editor {
      width: 100%;
      padding: 0 1rem !important;

      .ck-editor__top {
        .ck-toolbar {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .ck-editor__editable_inline {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        overflow: auto;

        &.ck-content {
          height: 77vh !important;
        }
      }
    }

    #revision-viewer-sidebar {
      &.sidebar-container {
        background: var(--ck-color-toolbar-background);
        margin-left: -1px;

        .ck-revision-history-sidebar {
          height: 100% !important;
        }
      }
    }
  }

  .editors-holder {
    width: 100%;
  }
}

//gridstack

.grid-stack-item-content {
  border-radius: 6px;
  text-align: left;
  background-color: #fff;
  overflow: hidden !important;
}

.gridstack-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background-color: #f2f9fa;
  color: #07162b;

  & div:first-child {
    font-weight: 500;
  }
}

.gridstack-panel-icon a {
  margin: 0 0 0 12px !important;
  box-shadow: none !important;
}

.gridstack-panel-icon i {
  color: #797979 !important;
}

.expand-remove {
  display: none;
}

.expand.expand-remove {
  display: block;
  width: 100% !important;
  height: calc(100vh - 150px) !important;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
}

.expand.expand-remove .ui-resizable-se {
  background-image: none;
  cursor: none;
}

.expand.expand.expand-remove .btn-floating:first-child i {
  animation: animName 1s ease-out;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}

.expand.expand.expand-remove .fa-expand::before {
  content: "\f066";
}

.panel-collapsed {
  height: 75px !important;
}

.panel-collapsed .grid-stack-item-content {
  overflow-y: hidden !important;
}

.panel-collapsed .panel-collapse i:before {
  content: "\f107";
}

.grid-stack-item.ui-draggable-dragging .gridstack-panel-header {
  background-color: #f2f9fa;
  color: #07162b;
}

.grid-stack-item.ui-draggable-dragging .gridstack-panel-icon i {
  color: #07162b !important;
}

.panel-collapsed .gridstack-panel-icon {
  margin-right: 1.5rem;
}

.panel-collapsed .ui-resizable-handle.ui-resizable-se {
  right: 2% !important;
  bottom: 38% !important;
}

.gridstack-panel-body .classic-tabs .nav li {
  border: none;
}

.gridstack-panel-body {
  padding: 1rem 0.8rem 60px;
}

.gridstack-panel-body .tabs-cyan {
  background: none;
  background-color: #5a8bbd !important;
}

.no_checkbox > i.jstree-checkbox {
  display: none;
}
.jstree-proton
  {
    font-family: inherit !important;
  }
  .jstree-proton .jstree-open>.jstree-ocl::before
  {
    content:"\f0d7";
    font-family:'Font Awesome 6 Free';
    font-weight: 800;
    font-style: normal;
  }
  .jstree-proton .jstree-closed>.jstree-ocl::before
  {
    content:"\f0d8";
    font-family:'Font Awesome 6 Free';
    font-weight: 600;
    font-style: normal;
  }
  .jstree-proton .jstree-node, .jstree-proton .jstree-icon:not(.jstree-proton.jstree-checkbox-selection .jstree-clicked>.jstree-checkbox):not(.jstree-proton .jstree-checkbox)
  {
    background-image: none !important;
    line-height:30px !important;
  }
  .jstree-proton .jstree-themeicon-custom.fa-file::before
  {
    content:"\f15c" !important;
  }
  .jstree-proton .jstree-themeicon-custom.fa-folder
  {
    line-height: 30px !important;
  }
  .jstree-anchor {
   width: 95%;
   height: 35px !important;
   line-height: 35px !important;
   color:#4B597A !important;
  }
  .jstree-anchor.jstree-clicked
  {
    color: #febe10 !important;
  }
  .jstree-anchor.jstree-hovered{
    background: transparent !important;
    color: inherit !important;
    box-shadow: none !important;
    font-weight: 400 !important;
    border-radius: 0 !important;
  }
 
  .jstree-wholerow-ul li.jstree-last:first-child > div
  {
    background-color: #F2F9FA !important;
  }
  .jstree-children li:nth-child(odd) > div
  {
    background-color: #FFF !important;
  }
  .jstree-children li:nth-child(even) > div
  {
    background-color: #F2F9FA !important;
  }

  // .jstree-proton ul.jstree-children:first-child .jstree-node:nth-child(odd)
  // {
  //     background-color: #FFF !important;
  // }
  // .jstree-proton ul.jstree-children:first-child .jstree-node:nth-child(even)
  // {
  //     background-color: #F2F9FA !important;
  // }
  // .jstree-proton .jstree-node:nth-child(odd) >.jstree-anchor
  // {
  //   background-color: #FFF !important;
  // }
  // .jstree-proton .jstree-node:nth-child(even) >.jstree-anchor
  // {
  //   background-color: #F2F9FA !important;
  // }
  .jstree-children li
  {
    background-color: transparent !important;
  }
.document-index-tree {
  .jstree-proton .jstree-node {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .jstree-wholerow {
   // border-bottom: 1px solid #ccc !important;
    height: 36px !important;
  }

  .jstree-node {
    padding-top: 2px;
    padding-bottom: 2px;
    line-height: 35px !important;
  }

  .jstree-anchor {
    padding-top: 5px;
  }

  .jstree-ocl {
    margin-top: 5px !important;
  }
}

.add-topic-sidebar {
  font-weight: 500;
  overflow: scroll;
  font-size: 14px;
  max-height: 500px;
  min-height: 500px;
  background-color: #f5f5f5;

  .jstree-disabled {
    color: #4B597A !important;
  }
}

.scrollbar-primary::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #145178;
}
.ckeditorJsTree .scrollbar-primary::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

.ckeditorJsTree .scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #a8a8a8;
}

.expand-body {
  .iframe-container {
    height: 100%;
  }

  .iframe-container-collapse {
    height: 100%;
  }

  .imergix-iframe {
    width: 100%;
    border: none;
    height: 100%;

    @media all and (max-width: 1259px) {
      width: 100%;
    }
  }

  .wordml-content-iframe {
    width: calc(100% - 19rem);
    border: none;
    position: absolute;
    height: calc(100% - 65px);

    @media all and (max-width: 1259px) {
      width: 100%;
    }
  }

  .taxonomy-iframe {
    width: calc(100% - 19rem);
    border: none;
    position: absolute;
    height: calc(100vh - 130px);

    @media all and (max-width: 1259px) {
      width: 100%;
    }
  }

  .navbar.scrolling-navbar {
    position: fixed;
    width: calc(100% - 15rem);
    top: 0;
    right: 0;
    z-index: 1035;
  }
}

.expand-body.notFound .navbar.scrolling-navbar {
  width: 100%;
}

// form d changes
.collapse-body {
  main.contentpane {
    padding-left: 60px;
  }

  .iframe-container {
    width: 90%;
    height: 100%;
  }

  .iframe-container-collapse {
    width: 100%;
    height: 100%;
  }

  .imergix-iframe {
    width: 100%;
    border: none;
    //position: absolute;
    height: 100%;

    @media all and (max-width: 1259px) {
      width: 100%;
    }
  }

  .wordml-content-iframe {
    width: calc(100% - 7.5rem);
    border: none;
    position: absolute;
    height: calc(100% - 65px);

    @media all and (max-width: 1259px) {
      width: 100%;
    }
  }

  .taxonomy-iframe {
    width: 100%;
    border: none;
    position: absolute;
    height: 100%;

    @media all and (max-width: 1259px) {
      width: 100%;
    }
  }

  .navbar.scrolling-navbar {
    position: fixed;
    width: calc(100% - 3rem);
    top: 0;
    right: 0;
    z-index: 1035;
  }
}

.sheetjs-modal,
.spreadsheet-modal {
  width: calc(100% - 20px);
  max-width: calc(100% - 20px);
  height: 100%;
}

.taxonomy-iframe-container {
  margin-left: 0;
  margin-right: 0;
}

table.jss:not(.no-shade) tbody tr:nth-child(even) {
  background-color: rgb(204, 238, 255);
}

table.jss tbody tr:nth-child(even) > td {
  background-color: inherit !important;
  color: inherit !important;
}

table.jss:not(.no-shade) tbody tr:nth-child(odd) {
  background-color: #fff;
}

table.jss tbody tr:nth-child(odd) > td {
  background-color: inherit !important;
  color: inherit !important;
}

.show {
  display: inline-block;
}

.hide {
  display: none;
}

.select-check {
  display: flex;
  align-items: center;

  .form-check {
    position: relative;
    top: 7px;
  }
}

.cdk-overlay-container {
  .mdb-select-dropdown {
    .mdb-option {
      .mdb-option-text {
        .i-items {
          .i-number {
            width: 30px;
            display: inline-block;
            font-weight: 500;
          }

          width: 210px;
          display: inline-block;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.custom-class {
  .dropdown-list {
    top: unset !important;
    left: unset !important;
  }

  .selected-list {
    .c-btn {
      max-height: 85px;
      overflow-y: auto;
    }

    .c-list {
      .c-token {
        background-color: #ededed !important;

        .c-label {
          width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
          color: #495057;
          font-weight: 400;
        }
      }
    }

    .c-remove {
      &.clear-all {
        top: 10px;
        right: 15px;

        c-icon {
          svg {
            border: 1px solid red;
            background: red;
          }
        }
      }

      c-icon {
        padding: 1px;

        svg {
          fill: #fff;
          height: 12px;
          width: 12px;
          padding: 2px;
          border: 1px solid gray;
          background: gray;
          font-weight: 500;
          border-radius: 50%;
        }
      }
    }
  }
}

.container-collapsive {
  &.formd {
    mdb-accordion-item {
      mdb-accordion-item-head {
        div {
          a {
            h5 {
              font-size: 14px !important;
            }
          }
        }
      }

      mdb-accordion-item-body {
        .card-body {
          padding: 1.25rem !important;
        }
      }
    }
  }

  mdb-accordion-item {
    .active {
      mdb-accordion-item-body {
       // border-bottom: 1px solid #205274;
      }
    }

    mdb-accordion-item-head {
      // border-bottom: 1px solid #205274;
      // border-left: 1px solid #205274;
      // border-right: 1px solid #205274;
      background-color: #fff;
      text-transform: uppercase;
      font-weight: 500 !important;

      div {
        a {
          h5 {
            color: #07162b !important;
            font-size: 14px !important;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media (max-height: 992px) {
  .side-nav .logo-wrapper,
  .side-nav .logo-wrapper a {
    height: 60px !important;
  }
}

@media screen and (min-width: 993px) {
  .side-nav .logo-wrapper img {
    height: 60px;
    padding: 8.14px 30px !important;
  }
}

.custom-dropdown .mdb-select-value {
  padding: 0.6rem 0 0.4rem !important;
  box-shadow: 0px 0px transparent !important;
}

.custom-dropdown .input-group > .form-control {
  width: auto !important;
}

.jstree-proton .jstree-wholerow-hovered {
  background-color: transparent !important;
}

.jstree-proton .jstree-wholerow-clicked {
  background: transparent !important;
}

.jstree-proton.jstree-checkbox-no-clicked .jstree-clicked {
 // color: #ffffff !important;
  background: #eee !important;
}

.box {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("/fe_assets/images/internet.jpg");

  p {
    top: 12%;
    font-size: 30px;
    font-weight: 300;
    position: absolute;
  }
}

.progress-event-bar {
  display: inline-flex;

  .progress {
    height: 18px !important;
    width: 100px !important;
    margin-bottom: 0;
    border: 1px solid #a3a1a1;

    .progress-bar {
      height: 18px !important;
      background-color: #0d47a1 !important;
    }
  }
}

.classic-tabs .nav.tabs-cyan li a.active {
  border-color: #fff !important;
}

.classic-tabs .nav.tabs-cyan li a {
  font-weight: 500 !important;
  color: #ffffff;
}

.classic-tabs .nav li {
  border: solid 0.1px #eee;
  border-left: 0;
  border-bottom: 0;
  line-height: 0.5;
}

.classic-tabs .nav li:first-child {
  margin-left: 0px !important;
}

.audit-tabs .tab-content {
  margin-top: 2.5rem;
  display: contents;
}

.audit-tabs .md-tabs {
  overflow-y: initial;
  background-color: transparent;
  box-shadow: none;
  position: fixed;
  padding: 10px;
  border-bottom: 2px solid #ddd;
  width: 100%;
  background-color: white;
  top: 49px;
}

.audit-tabs .md-tabs .nav-item {
  flex-grow: 0;
  float: left;
  margin-bottom: -1px;
  position: relative;
  display: block;
}

.audit-tabs .md-tabs .nav-link.active,
.audit-tabs .md-tabs .nav-item.open .nav-link {
  color: white;
  background-color: #145178;
  border-bottom-color: transparent;
}

.audit-tabs .md-tabs .nav-link {
  color: #145178;
  font-weight: 400;
}

.switch.round label {
  font-size: 0.8rem;
}

.switch.round label .lever {
  width: 32px;
  height: 18px;
  border-radius: 10em;
  margin: 0 0.3rem;
}

.switch.round label .lever:after {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 4px;
  top: 4px;
}

.switch.blue-white-switch label .lever:after {
  background-color: #fff;
}

.switch label input[type="checkbox"]:checked + .lever {
  background-color: #FFDF88;
}

.switch label input[type="checkbox"]:checked + .lever:after {
  background-color: #FFDF88;
  left: 1.2rem;
}

.classic-tabs .nav li a {
  padding: 20px 16px !important;
}

#projectStatus table tbody mdb-icon,
#projectStatus table tbody i {
  font-size: 1.25em;
}

.sticky {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.medium-sheet-modal.modal-dialog {
  width: calc(80% - 20px);
  max-width: calc(80% - 20px);
  height: 100%;
}

#ea-tsr-editor .ck .ck-annotation__main p {
  text-align: left;
}

.required label::after {
  content: "*" !important;
  color: red;
  margin-left: 5px;
  display: unset !important;
  position: unset !important;
  transition: none !important;
  opacity: unset !important;
}

.select .mdb-select-value {
  margin-left: 20px;
}

.select .mdb-select-value-label {
  margin: 20px;
}

.analytics .md-form {
  margin-right: 40px;
}

.md-form .form-control:disabled, .md-form .form-control.disabled, .disableColor .mdb-select-value  
{
  color: #aaa;
  background-color: #eee !important;
}
.d-flex.flex-wrap > div{
  width: 49%;
}
.card-box
{
  border: 1px solid #D7EFF4 !important;
  background-color:#fff;
}
.ck.ck-toolbar
{
  border-bottom-left-radius:6px !important;
  border-bottom-right-radius:6px !important;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)
{
  border:none !important;
}

button.ck.ck-button
{
  color: #6e87c8;
}

textarea
{
  resize: none;
}
.tab-icon{
  width: 26px;
  height: 26px;
  display: flex;
  background-color: #f6f6f6;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}


// ck editor design 

.ck.ck-button:active, .ck.ck-button:focus, a.ck.ck-button:active, a.ck.ck-button:focus
{
  box-shadow: none !important;
  border: none !important;
}

.ck.ck-dropdown__panel
{
  background:#F2F9FA !important;
  border:1px solid #D7EFF4 !important;
  box-shadow: none !important;
}

.ck.ck-dropdown__panel button.ck.ck-button,.ck.ck-button.ck-on, a.ck.ck-button.ck-on
{
  color: #07162b !important;
}
.ck.ck-dropdown__panel button.ck.ck-button:hover
{
  color: #00e5ff !important;
  cursor: pointer;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable)
{
  border:none !important;
  box-shadow: none !important;
}

.ck.ck-button:not(.ck-disabled):hover, a.ck.ck-button:not(.ck-disabled):hover
{
  background:#07162b !important;
  cursor: pointer;
}
.ck.ck-revision-history-sidebar__header
{
  border-bottom: none !important;
}
.ck.ck-revision-history-sidebar__header .ck-revision-history-sidebar__header__label
{
  font-weight: 500 !important;
}
.ck.ck-revision-history-sidebar__time-period .ck-revision-history-sidebar__time-period__label,
.ck .ck-comments-archive .ck-comments-archive__header,
.ck.ck-revision-history-sidebar__revision-wrapper .ck-revision-history-sidebar__revision .ck-revision-history-sidebar__revision__date,
.ck.ck-revision-history-sidebar__revision-author .ck-revision-history-sidebar__revision-author__name
{
  font-weight:400 !important;
}
.ck.ck-button.ck-revision-history-ui__back-to-editing
{
 // color: #07162b !important;
  background-color:#07162b !important;
}

.ck.ck-list
{
 
  border: 1px solid #D7EFF4;
  background: #F2F9FA !important;
}
.ck.ck-revision-history-sidebar__revision-wrapper .ck-revision-history-sidebar__revision,.ck .ck-comment__input-container,.ck.ck-annotation-wrapper
{
  color:#07162b !important;
  background: #FAFAFA  !important;
  box-shadow: none !important;
  border: 1px solid #e0e0e0;
  border-radius: 10px 10px 10px 0 !important;
}
.ck.ck-revision-history-sidebar__revision-name>.ck-labeled-field-view__input-wrapper>.ck-input
{
  font-weight: 400 !important;
}
.ck.ck-revision-history-sidebar__revision-wrapper .ck-revision-history-sidebar__revision
{
  box-shadow: none !important;
  border-width: 1px !important;
  border-color: #e0e0e0 !important;
}
.ck.ck-revision-history-sidebar__revision-wrapper .ck-revision-history-sidebar__revision.ck-revision-history-sidebar__revision_selected .ck-revision-history-sidebar__revision-name .ck-input
{
  color:#07162b !important;
}
.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se input,.ck.ck-revision-history-sidebar__revision-wrapper .ck-revision-history-sidebar__revision.ck-revision-history-sidebar__revision_selected .ck-revision-history-sidebar__revision-name .ck-input {
  box-shadow: none !important;
  border-color:#d7eff4 !important;
  border-radius: 6px !important;
  background:#fff !important;
}

.ck .ck-comment, .ck .ck-comment__input-container, .ck .ck-thread__comment-count, .ck .ck-thread__header
{
  border:none !important;
}
.ck-comment__input-actions .ck.ck-button.ck-comment__input-actions--submit
{
  color:#07162b !important;
  background-color: #febe10 !important;
}
.ck-comment__input-actions .ck.ck-button.ck-comment__input-actions--cancel
{
  border:2px solid #f57c00 !important;
  color:#f57c00 !important;
}
.ck .ck-insert-table-dropdown-grid-box.ck-on
{
  border: 1px solid #febe10 !important;
  background: #FFDF88 !important;
}
.arrow .mdb-select-arrow.disabled{
  display: none;
 }
 .ck .ck-suggestion-insertion .ck-suggestion
{
  border-left-color: transparent !important;
}
.as-split-gutter
{
  background-color:#f2f9fa !important;
}
.ck.ck-document-outline > div
{
  padding-left: 10px !important;
  &:hover
  {
    text-decoration: none !important;
  }
}
.ck.ck-document-outline > div:nth-child(odd)
{
  background-color: #fff !important;
}
.ck.ck-revision-history-sidebar__revision .ck-revision-history-sidebar__revision__actions .ck-dropdown__button
{
  color: grey !important;
}
.userIcon
{
  width:30px;
  height: 30px;
  box-shadow: none !important;
}
.demo-container .ck-sidebar-item
{
    left: 46px;
    width: 88%;
}
.ck .ck-thread
{
  background-color: transparent !important;
}
.ck .ck-annotation__info-name, .ck .ck-annotation__info-time,.ck .ck-suggestion-type
{
  font-weight: 500 !important;
}
.ck.ck-user__img
{
  border:none !important
}
.tabsArchive-wrapper {
  background: #fff !important;
  margin-left: 1rem;
  padding: 1rem 0;
}
.tabsArchive__item {
  color: #4B597A;
  font-size: 14px;
  border: 1px solid #4B597a !important;
  border-radius: 5px;
  margin: 0 0.5rem;
  opacity: 1 !important;
  background: transparent !important;
}
.tabsArchive__item.active {
  background: #4B597A !important;
  color: #fff;
}
.sidebar-archive{
  border-color: transparent !important;
}
.ck .ck-comment__input
{
  background-color: #fff !important;
}
.ck .ck-comment__input-actions
{
  padding-top: 10px;
  background-color: #fafafa !important;
}
.ck .ck-thread__input
{
  min-height: auto !important;
}
.white-skin .boir-layout-form .pills-primary li.active {
  position: relative !important;
}
.white-skin .boir-layout-form .pills-primary li.active::after {
  content: "\f0d7";
  font-family: "Font Awesome 6 free";
  font-weight: 900;
  position: absolute;
  transform: translate(-50%, 0%);
  left: 50%;
  top: 54%;
  font-size: x-large;
  color: #febe10;
  z-index: 1000 !important;
}
.white-skin .boir-layout-form .pills-primary .nav-link.active {
  background-color: #febe10 !important;
  font-weight: 500;
  color: #07162b;
}
.boir-layout-form .card.tab-content.card-pos {
  top: -18px;
  right: 7px;
}
.white-skin .boir-layout-form .pills-primary .nav-link
{
  color: #4B597A;
}
// .white-skin .boir-layout-form .pills-primary li:first-child{
//   padding-left:21px;
// }
.white-skin .boir-layout-form .pills-primary li:last-child{
  padding-right:22px;
}
.white-skin .boir-layout-form .pills-primary li.disabled{
  opacity:.5;
}
.badge
{
  font-weight: 500 !important;
  box-shadow: none !important;
  background-color: #08162a;
}
.file-container {
  height: 40px;
  padding: 0px 15px;
  border: 1px solid lightgray;
  border-radius: 20px;
  align-items: center;
  box-shadow: 1px 2px 3px rgb(0 0 0 / 20%);
  cursor: pointer;

  &.selected {
    .file-container-highlight {
      top: -4px;
    left: -5px;
    width: 12px;
    height: 1.375rem;
    position: relative;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-origin: 100% 100%;
    border-right: 2px solid #febe10;
    border-bottom: 2px solid #febe10;
      
    }
    .file-container-name{
      margin-left:14px !important;
    }
  }

  &.disabled {
    background-color: rgba(0,0,0,0.1);
    cursor: not-allowed !important;            
  }
 
  .file-container-highlight {
    border:2px solid #8a8a8a;
    width: 18px;
    height: 18px;
    // margin-top: 1px;
  }
  .file-container-name {
    font-size: 14px;
    font-family: sans-serif;
    font-weight: 600;
    color: #145178;
  }        
}

.selection-box {
  border: 2px solid lightgray;
  padding: 20px 25px;
  position: relative;
  // box-shadow: 1px 2px 3px lightgray;
  cursor: pointer;
  margin-right: 15px;
  &:last-child
  {
    margin-right: 0;
  }

  &:hover {
    transition: 0.5s;
    border-color: #bfcbd4;
    // box-shadow: 1px 2px 3px #145178;
    .file-pdf {
      color: #f40f02 !important;
    }
    .file-code {
      color: #f06529 !important;
    }
    .file-word {
      color: #1480d8 !important;
    }
  }

  &:hover > .overlay-text {
    display: block;
    transition: 0.5s;
  }

  &.selected {
    border: 2px solid #145178;
    // box-shadow: 1px 2px 3px #145178;
    .file-pdf {
      color: #f40f02 !important;
    }
    .file-code {
      color: #f06529 !important;
    }
    .file-word {
      color: #1480d8 !important;
    }

    .overlay-text {
      display: block;
    }            
  }

  &.file-pdf-box {
    border: 2px solid #f40f02 !important;
  }
  &.file-word-box {
    border: 2px solid #1480d8 !important;
  }
  &.file-code-box {
    border: 2px solid #f06529 !important;
  }

  .fas {
    font-size: 40px;
  }

  .overlay-text {
    // background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    // color: #fff;
    color: #145178;
    font-weight: 700;
    width: 100%;
    bottom: 0;
    right: 0;
    text-align: center;
    // padding: 2px;            
    display: none;
    font-size: 12px;
    font-family: sans-serif;
  }
}
.modal-dialog {
 display: flex;
 align-items: center; 
 height: 100%;
}
.jstree-proton .jstree-disabled.jstree-hovered{
  background-color: transparent !important;
  color: #febe10 !important;
}
i.jstree-icon.jstree-checkbox {
  margin-top: 5px;
}
body.white-skin.expand-body.editor-layout-scrollDisable.modal-open.editor-layout-scrollDisable-Popup {
  overflow: hidden;
}
mdb-accordion-item-head.item-head-title.custom-item-head-title h5.mb-0 {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.btn-discord
{
  background-color: #0000006b !important;
  border: 2px solid #0000 !important;
}
.btn-outline-discord
{
  color:#899CCC !important;
  border:2px solid #899CCC !important;
}
.search input::placeholder
{
  color:#d7ecf4;
}
.white-skin .btn-dark
{
  background-color:#07162B !important;
}
.toast-container .ngx-toastr,#toast-container > mdb-toast-component
{
  padding: 15px 15px 10px 15px !important;
  opacity: 1 !important;
}
.toast-success,.toast-error,.toast-warning,.toast-info,.white-skin .md-toast-error,.white-skin .md-toast-success,.white-skin .md-toast-warning,.white-skin .md-toast-info
{
  background-image: none !important;
}
.filterActReps span.mdb-select-placeholder {
  color: #495057 !important;
}
.pac-container
{
  z-index: 9999 !important;
}

.searchOwner mdb-icon {
  font-size: 14px !important; 
 }
 .hidemenu-arrow .mdb-accordion-indicator {
  display: none;
}
.mdb-select-dropdown,.mdb-select-options-wrapper
{
  background: #fff !important;
}
.customTooltip {
  min-width: 320px !important;
}
.customTooltip a.introjs-button.introjs-nextbutton {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  text-shadow: 0 0 black;
  font-family: "Poppins" !important;
}
.customTooltip a.introjs-button.introjs-prevbutton {
  background-color: #F3F4F6;
  color: #000;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  font-weight: 500 !important;
  font-family: "Poppins" !important;
}
.customTooltip .introjs-helperNumberLayer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
}
.customTooltip .introjs-button:focus {
  box-shadow: none;
}
.introjs-tooltip.customTooltip.introjs-bottom-left-aligned {
  border-radius: 7px;
}
.customTooltip .introjs-disabled {
  color: #9e9e9e !important;
}
.customTooltip .introjs-tooltipbuttons {
  border-top: none;
  border-radius: 0 0 7px 7px;
}
.customTooltip h1.introjs-tooltip-title {
  font-family: "Poppins" !important;
}
.customTooltip .introjs-tooltiptext {
  font-family: "Poppins" !important;
}
// .customTooltip::before {
//   content: "";
//   position: absolute;
//   top: 4px;
//   right: 4px;
//   bottom: 4px;
//   left: 4px;
//   border: 2px solid #007bff;
// }
.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 17%) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
}
// .navbar.navbar-expand-md links, .navbar.navbar-expand-md navlinks {
//   width: 30% !important;
// }

.anchor {
  cursor: pointer;
}
.customhideTooltip a.introjs-skipbutton {
  display: none;
}


// New UI Design Changes    ......................>>

body{
  background-color: #fff;
  font-family: "Poppins" !important;
}

.card {
  background-color: #fff;
}
.back-option
{
  border-radius: 5px;
  padding: 6px 12px;
  border:1px solid #ccc;
}
.table-border {
  border-radius: 5px;
  border: 1px solid #e7e7e7;
}
table:not(.picker__table):not(.ck-content .table table) thead
{
  background-color: transparent !important;
  border-bottom: 2px solid rgba(0,0,0,.168627451) !important;
  bottom:0 !important;
  height: 50px !important;
}

table:not(.picker__table):not(.ck-content .table table) thead tr th
{
  font-size: 14px !important;
  color: #000 !important;
}
table:not(.picker__table):not(.ck-content .table table) tbody tr:hover:not(.grid-stack table tbody tr)
{
  outline: none !important;
}
table:not(.picker__table):not(.ck-content .table table) tbody tr
{
  border-bottom-color:#0000001f !important;
}
table:not(.picker__table):not(.ck-content .table table) tbody td
{
  font-size: 14px !important;
  padding: 17px 10px !important;
  color: #000;
}
.dropdown-menu
{
  padding: 8px 0 8px 0 !important;
  border:none !important;
  background-color: #fff !important;
  box-shadow: 0 2px 4px -1px #0003,0 4px 5px #00000024,0 1px 10px #0000001f !important;
}
body button.action-btn:hover
{
  color: initial !important;
  background-color: transparent !important;
}
body .dropdown .dropdown-menu.dropdown-primary .dropdown-item:hover {
  background-color: rgba(0, 0, 0, .04) !important;
  color: inherit !important;
  box-shadow: none !important;
}
.white-skin .dropdown-menu a
{
  padding: 12 16px !important;
  font-size: 14px !important;
}
.md-form label:not(.form-check-label),.md-form label, .md-form .mdb-select .mdb-select-label
{
  font-size: 14px !important;
}
.md-form input.form-control, .md-form textarea.form-control:not(.textarea-resize), .mdb-select-value, .file-field input.file-path
{
  border-color:#79747e !important;
  height: 42px !important;
}
.md-form input.form-control:focus, .md-form textarea.form-control:focus, .mdb-select-value:focus, .file-field input.file-path:focus
{
  border-color: #79747e !important;
}
.error-message, .success-message
{
  top:45px !important;
}
.side-nav
{
  background-color: #fff !important;
  border-right: 1px solid #e7e7e7 !important;
}
.sidenav-collapse-icon
{
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid lightgray;
}
.white-skin .side-nav .collapsible .card .card-header a h5, .white-skin .side-nav .collapsible .card.active > mdb-accordion-item-head .card-header a h5
{
  color: #000;
}
.menu-active .card-header a, .router-link-active .card-header a
{
  background-color: rgba(0, 0, 0, .04) !important;
}
.white-skin .btn-primary:not(.dropdown-toggle)
{
  height: 42px !important;
  background-color: #000 !important;
  border: 1px solid #000 !important;
  color: #fff !important;
  font-size: 14px !important;
  font-family: 'Poppins';
  &:hover
  {
    background-color: #fff !important;
    color: #000 !important;
  }
}

.btn-outline-orange
{
  background-color: #fff !important;
  border: 1px solid #000 !important;
  color: #000 !important;
  &:hover
  {
    background-color: #000 !important;
    color: #fff !important;
  }
}

.white-skin input[type=checkbox]:checked + label:before {
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #000;
  background-color: #000;
}
.search input::placeholder
{
  color:grey;
}

.md-form.search [icon=search] {

  top: 16px;
  color:grey;
}
.mdb-select-value {
  // padding: 0.85rem 0.5rem 0.8rem !important;
}
.white-skin .btn
{
  height: 42px;
  padding: 0 12px !important;
  font-size: 14px !important;
}
// .ng-autocomplete .autocomplete-container .input-container input, .filter-container .form-control, .form-control:not(.textarea-resize)
// {
//   height: 52px !important;
// }

.white-skin .boir-layout-form .pills-primary .nav-link.active {
  background-color: #000 !important;
  color: #fff;
}
.white-skin .boir-layout-form .pills-primary li.active::after
{
  color: #000;
}
.bg-light.border-r-10 {
  background-color: #EFEFEF !important;
  margin: 20px 0;
  padding: 2px 12px;
  border-radius: 6px !important;
  font-weight: 500;
}
.modal-dialog .modal-content
{
  background-color: #fff !important;
}
.gridstack-panel-header{
  background-color: #EFEFEF;
  color: #000;
}
.white-skin .card-header a h5 img {
  filter: invert(1);
}
.white-skin .side-nav .collapsible .card-body li a {
  color: #000 !important;
  font-weight: 500;
}
.boi-title {
  font-size: 26px;
  font-weight: 300;
  line-height: normal;
  text-align: center;
}
.title-sign {
  font-size: 34px;
  font-weight: 500;
  text-align: left;
}
button.waves-light.w-100.ct-font-size.btn-outline-black.btn {
  font-size: 14px !important;
}
.card-box
{
  border:1px solid #79747e !important;
}
.btn-black:disabled,.btn-black.disabled
{
  color: grey !important;
}
.datepicker-icon
{
  color: #79747e !important;
}
table:not(.picker__table):not(.ck-content .table table) tbody td a:hover
{
  color: #000 !important;
}
.custom-p .mdb-select-value {
  height: 24px !important;
}
.custom-p .datepicker-calendar-icon.form-control {
  height: 40px !important;
  // border: 1px solid #79747e;
}
.btn {
  font-weight: 500;
}
.white-skin .boir-layout-form .pills-primary .nav-link{
  font-weight: 500;
  font-size: 15px;
}
.rounded-circle{
  color: #fff;
}
i.fa-exclamation.fa-1x.fas {
  color: #000;
}

.white-skin .navbar {
  background-color: #fff;
  color: #000;
  font-weight: 400;
}
.cyan-text {
  color: #000 !important;
}
button.bk-outline.btn-outline-black.btn:hover {
  background-color: #000 !important;
  color: #fff !important;
  border: 2px solid #000 !important;
}
.btn-black{
  border: 2px solid #000 !important;
}
.btn-black:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 2px solid #000 !important;
}
.logout-icon .rounded-circle {
  color: #000 !important;
}
.bc-align .breadcrumb {
  margin-left: 26px;
}
.text-danger {
  font-size: 13px;
}
.custom-form-field-handler.md-textarea textarea {
  padding: 10px 6px !important;
}
.white-skin .pills-primary .nav-link.active {
  background-color: #000 !important;
}
.disable-input .form-control:disabled, .disable-input .form-control[readonly]{
  background-color: #bdbdbd !important;
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #332d2d7d;
  border: 2px solid #332d2d00;
}
.cal-event-actions .btn.btn-sm {
  height: auto;
  padding: 0 !important;
  color: #332d2d;
}
.form-check-input[type=radio]:checked + label:after, .form-check-input[type=radio].with-gap:checked + label:after, label.btn input[type=radio]:checked + label:after, label.btn input[type=radio].with-gap:checked + label:after {
  background-color: #000;
}
.form-check-input[type=radio]:checked + label:after, .form-check-input[type=radio].with-gap:checked + label:before, .form-check-input[type=radio].with-gap:checked + label:after, label.btn input[type=radio]:checked + label:after, label.btn input[type=radio].with-gap:checked + label:before, label.btn input[type=radio].with-gap:checked + label:after {
  border: 2px solid #000000;
}
.md-toast-success
{
  background-color: #51A351 !important;
}
.md-toast-error 
{
  background-color: #BD362F !important;
}